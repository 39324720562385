import { callApi } from "../../utils/api";

const entity = "fast_access";
const messageEntity = "el acceso";
const messagePluralEntity = "los accesos";
const reducerEntity = "fast_accesse";

export const createFastAccess = (item, options = {}) => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      //The company id is supposed to be stored here when a client scan/type a code 
      const sys_company_id = item?.sys_company_id ? item?.sys_company_id :  state.access?.sysCompanyId;

      const name = item?.name ? item?.name : localStorage.getItem('@fast_access_name');
      const country_code = item?.country_code ? item?.country_code : localStorage.getItem('@fast_access_country_code');
      const phone = item?.phone ? item?.phone : localStorage.getItem('@fast_access_phone');

      // companies visited
      const companiesVisitedRawData = localStorage.getItem('@companies_visited')
      const companiesVisited = companiesVisitedRawData ? JSON.parse(companiesVisitedRawData) : {}

      if ( sys_company_id && name && country_code && phone && !companiesVisited[sys_company_id] && !state.app?.client) {
        let res
        try{
            res = await callApi(`/${entity}`, {
              method: "POST",
              body: JSON.stringify({
                sys_company_id,
                name,
                country_code,
                phone,
              }),
              shouldSkipStatusError: true,
            });
        }catch(e){
            res = {}
        }

        // The company is added to companies visited
        localStorage.setItem('@companies_visited', JSON.stringify({...companiesVisited, [sys_company_id]: true}))

        if (res.status == 200) {
          dispatch({
            type: `ADD_${reducerEntity.toUpperCase()}`,
            response: res.body,
          });

          return res.body;
        }
      } else if (item?.name && item?.country_code && item?.phone) {
        //The data is stored in case the client have not scanned/typed a code

        localStorage.setItem("@fast_access_name", name);
        localStorage.setItem("@fast_access_country_code", country_code);
        localStorage.setItem("@fast_access_phone", phone);
      }

      return {};
    } catch (e) {
      return {};
    }
  };
};
