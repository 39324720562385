import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
    Button,
    Image,
    Modal,
    ModalContent,
    ModalDescription,
    Card,
    Form as SemanticForm,
    Dropdown,
    Flag,
} from 'semantic-ui-react'
import { Field, Form, Formik } from 'formik'

import Input from './components/InputForm'
import {createFastAccess} from './store/actions/fast_access'
import { validate, validatePhoneNumber } from "./utils/formValidations";
import AppStoreImg from './images/appstore_img.png'
import PlayStoreImg from './images/playstore_img.png'
import LogoImge from './images/logo1024.jpg'

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const countries = [
    { value: '+52', flag: 'mx', text: '+52' },
    { value: '+1', flag: 'us', text: '+1' },
]


const DownloadAppNotification = (props) => {
    const [open, setOpen] = useState(false)
    const [showInputs, setShowInputs] = useState(false)
    const [selectedLada, setSelectedLada] = useState(countries[0])
    const history = useHistory()

    const userAgent = navigator.userAgent
    const isMobile = (userAgent.includes('iPhone') || userAgent.includes('iPad') || userAgent.includes('Android')) ?? false
    
    const androidURL = 'https://play.google.com/store/apps/details?id=mx.restec&pcampaignid=web_share'
    const iosURL = 'https://apps.apple.com/mx/app/restec-descubre-y-saborea/id6463335518'

    let appURL

    if(userAgent.includes('Android'))
        appURL = androidURL
    if(userAgent.includes('iPhone') || userAgent.includes('iPad'))
        appURL = iosURL

    const dwnButtonText = isMobile ? 'Descargar App' : 'Registrarse'

    useEffect(() => {
        const timer = setTimeout(() => {
            if(userAgent.includes('Android') || userAgent.includes('iPhone') || userAgent.includes('iPad')){
                setOpen(true)
            }
        }, 120000)

        return () => clearTimeout(timer)
    }, [])

    const handleAppDownload = () => {
        if (isMobile) {
            window.open(appURL, '_blank')
        } else {
            history.push('/account_register?type=cliente')
        }
    }

    const handleContinueClick = () => {
        const name = localStorage.getItem('@fast_access_name');
        const country_code = localStorage.getItem('@fast_access_country_code');
        const phone = localStorage.getItem('@fast_access_phone');
        if((name && country_code && phone) || props.isClientAuthenticated){
            setOpen(false)
        }else{
            setShowInputs(true)
        }
    }

    const onSubmit = async (values, { setSubmitting }) => {
        values.country_code = selectedLada.value
        props.createFastAccess(values)
        setOpen(false)
    }

    return (
        <>
            <Modal open={open} size='small'>
                <ModalContent style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Image src={LogoImge} />
                    {!isMobile && (<ModalDescription>
                        ¿Desea registrarse en nuestra plataforma para una mejor experiencia?
                    </ModalDescription>)}
                    {isMobile && (<ModalDescription>
                        ¿Desea descargar nuestra aplicación para una mejor experiencia?
                    </ModalDescription>)}
                    <p></p>
                    {!showInputs && (
                        <Button style={{ margin: '0 auto' }} className='general-color' onClick={handleAppDownload}>{dwnButtonText}</Button>
                    )}
                    {!showInputs && (
                        <Button style={{ margin: '0 auto' }} className='general-color' onClick={handleContinueClick}>Continuar navegando</Button>
                    )}

                    {/* form for fast access */}
                    {showInputs && (
                        <Formik
                            initialValues={{}}
                            onSubmit={onSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form className='ui form'>
                                    <Card style={{margin: '0 auto'}}>
                                        <Card.Content>
                                            <Field
                                                type='text'
                                                name='name'
                                                component={Input}
                                                label='Nombre'
                                                placeholder='Nombre del cliente'
                                                validate={validate}
                                            />

                                            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '8px', marginTop: 5, }} >
                                                <SemanticForm.Field>
                                                    <div>Lada</div>
                                                    <Dropdown
                                                        placeholder='Selecciona una lada'
                                                        compact
                                                        selection
                                                        options={countries}
                                                        value={selectedLada.value}
                                                        onChange={(e, { value }) => {
                                                            const selectedOption = countries.find((lada) => lada.value === value)
                                                            setSelectedLada(selectedOption)
                                                        }}
                                                        trigger={
                                                            <span>
                                                                <Flag name={selectedLada.flag} />{' '}
                                                                {selectedLada.value}
                                                            </span>
                                                        }
                                                    />
                                                </SemanticForm.Field>

                                                <Field
                                                    style={{ flex: '1' }}
                                                    name='phone'
                                                    component={Input}
                                                    label='Whatsapp'
                                                    placeholder='{XXX} XXX-XXXX'
                                                    validate={validatePhoneNumber}
                                                    onInput={(event) => {
                                                        let value = event.target.value
                                                    
                                                        value = value.replace(/[^0-9]/g, '')
                                                    
                                                        if (value.length > 10) {
                                                            value = value.slice(0, 10)
                                                        }
                                                    
                                                        event.target.value = value
                                                    }}
                                                />
                                            </div>

                                            <Button
                                                style={{ margin: '1rem 0', width: '100%', }}
                                                className='general-color'
                                                type='submit'
                                                content={'Registrarse'}
                                            />
                                        </Card.Content>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    createFastAccess: (item, options) =>  dispatch(createFastAccess(item, options)),
})

const mapStateToProps = (state) => ({
    isClientAuthenticated: !!state.app?.client,
})

export default connect(mapStateToProps, mapDispatchToProps)(DownloadAppNotification)