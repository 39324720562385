import { formatColumn } from '.'

export const maxLengthValidate = (n) => (value) => {
	if (value?.length > n) {
		return `Máximo ${n} Caracteres`
	}
	return undefined
}

export const maxLengthValidateNotNull = (n) => (value) => {
	if (!value) {
		return 'No puede ser vacío.'
	}
	if (value.length > n) {
		return `Máximo ${n} Caracteres`
	}
	return undefined
}

export const maxLengthCharacterSpecialValidateNotNull =
	(n) => (value, characters) => {
		let reg = ['.', ',', ';', ':', '/', '-', '_']
		if (!value) {
			return 'No puede ser vacío.'
		}
		if (value.length > n) {
			return `Máximo ${n} Caracteres`
		}
		if (!characters.find((e) => e === value))
			return 'El caracter debe ser ". , ; : / - _"'
		return undefined
	}

export const maxNumericLength = (n) => (value) => {
	if ((value?.replace ? value.replace(/,/g, '').length : null) > n) {
		return 'Máximo ${n} digitos'
	}
	return undefined
}

export const validate = (value) => {
	if (!value) {
		return 'No puede ser vacío.'
	}
}

export const validateEmail = (value) => {
	if (!value) {
		return undefined
	} else if (!/\S+@\S+\.\S+/.test(value)) {
		return 'Debe ingresar un correo electrónico válido.'
	}
	return undefined
}

export const validateEmailNotNull = (value) => {
	if (!value) {
		return 'No puede ser vacío.'
	}
	if (!/\S+@\S+\.\S+/.test(value)) {
		return 'Debe ingresar un correo electrónico válido.'
	}
	return undefined
}

export const textConfirmationValidate = (textConfirmation) => (value) => {
	if (value !== textConfirmation) {
		return `Debes confirmar escribiendo '${textConfirmation}'`
	}
	return undefined
}

export const passwordConfirmValidate = (originalPassword) => (value) => {
	if (originalPassword && value) {
		if (originalPassword.localeCompare(value) !== 0) {
			return 'Las contraseñas no coinciden'
		}
	}
	return undefined
}

export const passwordConfirmValidateNotNull = (originalPassword) => (value) => {
	if (!value) {
		return 'No puede ser vacío.'
	}
	if (originalPassword.localeCompare(value) !== 0) {
		return 'Las contraseñas no coinciden'
	}
	return undefined
}

export const RFCValidate = (value) => {
	if (value) {
		let genericRFC = true
		const regexRFC =
			/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/
		let validado = value.match(regexRFC)

		if (!validado) {
			return 'RFC inválido'
		}
		const digitoVerificador = validado.pop(),
			rfcSinDigito = validado.slice(1).join(''),
			len = rfcSinDigito.length,
			//Obtener el digito esperado
			diccionario = '0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ',
			indice = len + 1
		let suma, digitoEsperado

		if (len == 12) suma = 0
		else suma = 481 //Ajuste para persona moral

		for (let i = 0; i < len; i++)
			suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i)
		digitoEsperado = 11 - (suma % 11)
		if (digitoEsperado == 11) digitoEsperado = 0
		else if (digitoEsperado == 10) digitoEsperado = 'A'

		//El dígito verificador coincide con el esperado?
		// o es un RFC Genérico (ventas a público general)?
		if (
			digitoVerificador != digitoEsperado &&
			(!genericRFC || rfcSinDigito + digitoVerificador != 'XAXX010101000')
		) {
			return 'RFC inválido'
		} else if (
			!genericRFC &&
			rfcSinDigito + digitoVerificador == 'XEXX010101000'
		) {
			return 'RFC inválido'
		}
		return undefined
	}
}

export const notLowerThanValidate = (initialValue) => (value) => {
	if (value) {
		if (value >= initialValue) {
			return `${value} debe ser menor a ${initialValue}`
		}
	}
	return undefined
}

export const notLowerThanNotNullValidate = (initialValue) => (value) => {
	if (!value) {
		return 'No puede ser vacío'
	}
	if (value && initialValue) {
		if (value >= initialValue) {
			return `${value} debe ser menor a ${initialValue}`
		}
	}
	return undefined
}

export const notLowerThanNumericValidate = (initialValue) => (value) => {
	if (value) {
		if (
			(value?.replace ? parseInt(value.replace(/,/g, '')) : null) >=
			initialValue
		) {
			return `${value} debe ser menor a ${initialValue}`
		}
	}
	return undefined
}

export const notGreaterThanValidate = (initialValue) => (value) => {
	if (value) {
		if (value <= initialValue) {
			return `${formatColumn('number', value)} debe ser mayor a ${formatColumn(
				'number',
				initialValue
			)}`
		}
	}
	return undefined
}

export const notGreaterThanOrEqualsValidate = (initialValue) => (value) => {
	if (value) {
		if (value < initialValue) {
			return `${value} debe ser mayor a ${initialValue}`
		}
	}
	return undefined
}

export const validatePhoneNumber = (value, required=true) => {
	if (!value) {
		if(required){
			return 'No puede ser vacío.'
		}
		return
	}
	else if(!/^\d{10}$/.test(value)){
		return 'Inserte un número válido'
	}
}